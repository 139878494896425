<template>
  <view class="content_tt">
    <view class="oderT">Please check your order:</view>
    <view class="oder_text">
      Hello, you have successfully paid for {{}} products and spent a total of $3290
      If you want to continue to spend, please return to the home page to buy again
    </view>
    <view class="inputDi">
      <input placeholder="Please upload your order number"/>
    </view>
    <button @click="getProduce()">Enviar</button>
    <u-popup ref="popups" mode="center" border-radius="20" height="500rpx" :closeable="true">
      <view>支付成功</view>
    </u-popup>
  </view>
</template>

<script>
export default {
  name: "saveOrderNumber",
  data() {
    return {}
  },
  methods: {
    getProduce() {
      this.$refs.popups.open();
    }
  }
}
</script>

<style>
.content_tt {
  background-color: rgba(200, 181, 245, 0.2);
  height: 100vh;
  padding: 30 rpx 20 rpx;
}

.oderT {
  font-size: 35 rpx;
  margin-bottom: 50 rpx;
}

.inputDi {
  padding: 10 rpx;
  border: 1 rpx solid rgba(123, 24, 169, 0.8);
  border-radius: 10 rpx;
  margin: 20 rpx 0 rpx 30 rpx;
}

.inputDi input {
  padding: 10 rpx;
}

.oder_text {
  font-size: 30 rpx;
  color: #da0e73;
  margin: 10 rpx 0 rpx 40 rpx;
}

button {
  background-color: #724ec6;
  color: #fff;
  padding: 5 rpx;
}
</style>
